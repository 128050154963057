import Button from 'UIKit/Button'
import Image from 'UIKit/Image'
import { NextLinkFromReactRouter } from 'UIKit/NextLink'
import Page from 'UIKit/Page'
import { RouteConfig } from 'config/constants/route'
import { ColumnCenter } from 'layout/Components/Column'
import { Trans } from 'react-i18next'

const NotFound = () => {
  return (
    <Page my="10vh" height="100%">
      <ColumnCenter>
        <Image src="/images/page-not-found.png" width={512} height={320} alt="page-not-found" />

        <Button
          as={NextLinkFromReactRouter}
          to={RouteConfig.Home}
          style={{
            fontWeight: 700,
          }}
          my="40px"
          width="100%"
          maxWidth="360px"
        >
          <Trans>Home</Trans>
        </Button>
      </ColumnCenter>
    </Page>
  )
}

export default NotFound
